import React, { Fragment, useState } from "react"
import styled from "styled-components"
import Lightbox from "react-images"

import GalleryImage from "./GalleryImage"
import { media } from "../../variables"
import Loader from "../Loader/Loader"

const Gallery = props => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)

  const openModal = (index, event) => {
    event.preventDefault()
    setCurrentImage(index)
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setCurrentImage(0)
    setModalIsOpen(false)
  }

  const gotoPrevious = () => {
    setCurrentImage(currentImage => currentImage - 1)
  }

  const gotoNext = () => {
    setCurrentImage(currentImage => currentImage + 1)
  }

  const gotoImage = index => {
    setCurrentImage(index)
  }

  const handleClickImage = () => {
    if (currentImage === props.galleryItems.length - 1) return
    gotoNext()
  }

  const createGalleryItems = items =>
    items.map((item, index) => (
      <li key={index}>
        <div>
          <GalleryImage {...item} onClick={e => openModal(index, e)} />
        </div>
      </li>
    ))

  return (
    <Fragment>
      <StyledGallery>{createGalleryItems(props.galleryItems)}</StyledGallery>
      <Lightbox
        currentImage={currentImage}
        images={props.galleryItems}
        isOpen={modalIsOpen}
        onClickImage={handleClickImage}
        onClickNext={gotoNext}
        onClickPrev={gotoPrevious}
        onClickThumbnail={gotoImage}
        onClose={closeModal}
        preventScroll={props.preventScroll}
        backdropClosesModal={true}
        spinner={Loader}
      />
    </Fragment>
  )
}

const StyledGallery = styled.ul`
  list-style-type: none;
  margin: 50px 0 0 0;
  padding: 0;
  display: flex;
  flex-flow: wrap;

  > li {
    font-size: 0;
    flex: 0 0 50%;

    ${media.sm`
      flex: 0 0 33%;
    `}

    > div {
      padding: 8px;
    }
  }
`

export default Gallery
