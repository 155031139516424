import React from "react"

import Seo from "../components/seo"
import Layout from "../components/Layout/Layout"
import Hero from "../components/Hero/Hero"
import Accommodations from "../components/Accommodations/Accommodations"
import Attractions from "../components/Attractions/Attractions"

const Home = () => {
  return (
    <Layout>
      <Seo
        noTemplate
        title="Best View Cabins | Cottages for Rent in Clementsport, Nova Scotia, Canada"
      />
      <Hero />
      <Accommodations />
      <Attractions />
    </Layout>
  )
}

export default Home
