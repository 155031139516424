import React from "react"
import Gallery from "../Gallery/Gallery"
import fortAnne from "./images/fort-anne.jpg"
import fortAnneThumb from "./images/fort-anne-thumb.jpg"
import lightHouse from "./images/light-house.jpg"
import lightHouseThumb from "./images/light-house-thumb.jpg"
import sunset from "./images/sunset.jpg"
import sunsetThumb from "./images/sunset-thumb.jpg"

const galleryItems = [
  {
    id: 1,
    src: fortAnne,
    caption: "Port-Royal National Historic Site",
    orientation: "landscape",
    alt: "Port-Royal National Historic Site",
    thumb: fortAnneThumb,
  },
  {
    id: 2,
    src: lightHouse,
    caption: "Annapolis Royal",
    orientation: "landscape",
    alt: "Lighthouse",
    thumb: lightHouseThumb,
  },
  {
    id: 3,
    src: sunset,
    caption: "Sunset over the Bay of Fundy",
    orientation: "landscape",
    alt: "Sunset",
    thumb: sunsetThumb,
  },
]

const AttractionsGallery = () => <Gallery galleryItems={galleryItems} />

export default AttractionsGallery
