import React from "react"
import styled from "styled-components"

import { vars, media } from "../../variables"
import { Container, H1 } from "../BaseElements/BaseElements"
import flag from "./images/flag-nova-scotia.jpg"
import lighthouseMobile from "./images/lighthouse-mobile.jpg"
import lighthouse from "./images/lighthouse.jpg"

const Hero = () => (
  <StyledHero>
    <HeroInner>
      <Container>
        <Content>
          <Title>Best View Cabins</Title>
          <FlagContainer>
            <img src={flag} alt="Nova Scotia Flag" />
          </FlagContainer>
          <Subtitle>Cabins on the Annapolis Basin</Subtitle>
        </Content>
      </Container>
    </HeroInner>
  </StyledHero>
)

const StyledHero = styled.div`
  color: #fff;
  background: url(${lighthouseMobile}) no-repeat;
  background-size: cover;
  min-height: 200px;
  position: relative;
  margin-top: 35px;
  margin-bottom: 50px;

  ${media.sm`
    margin-top: 38px;
    background: url(${lighthouse}) no-repeat;
    background-size: cover;
  `}

  &:before {
    position: absolute;
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2) 60%);
    z-index: 1;
  }
`

const HeroInner = styled.div`
  position: relative;
  z-index: 2;
`

const Content = styled.div`
  padding: 80px 0;
  text-align: center;
`

const Title = styled(H1)`
  font-style: italic;
  font-size: 68px;
  line-height: 68px;
  text-align: center;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
  padding: 0 0 12px 0;
`

const FlagContainer = styled.div`
  margin: 0 auto;
  position: relative;
  // padding: 0px 200px;
  display: inline-block;
  width: 100%;
  max-width: 460px;

  &:before,
  &:after {
    position: absolute;
    width: calc(50% - 40px);
    height: 1px;
    background: white;
    content: "";
    top: 50%;
    margin-top: -1px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }

  > img {
    width: 50px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
  }
`

const Subtitle = styled.div`
  font-family: ${vars.fontSecondary};
  font-style: italic;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
  font-size: 28px;
  padding-top: 5px;
`

export default Hero
