import React from "react"

import { Container } from "../BaseElements/BaseElements"
import Gallery from "../Gallery/Gallery"

import playground from "./images/playground.jpg"
import playgroundThumb from "./images/playground-thumb.jpg"
import pool from "./images/pool.jpg"
import poolThumb from "./images/pool-thumb.jpg"
import firepit from "./images/firepit.jpg"
import firepitThumb from "./images/firepit-thumb.jpg"
import bestviewGrounds1 from "./images/bestview-grounds1.jpg"
import bestviewGrounds1Thumb from "./images/bestview-grounds1-thumb.jpg"
import bestviewGrounds2 from "./images/bestview-grounds2.jpg"
import bestviewGrounds2Thumb from "./images/bestview-grounds2-thumb.jpg"
import bestviewGrounds3 from "./images/bestview-grounds3.jpg"
import bestviewGrounds3Thumb from "./images/bestview-grounds3-thumb.jpg"
import view from "./images/view.jpg"
import viewThumb from "./images/view-thumb.jpg"

const galleryItems = [
  {
    id: 1,
    src: bestviewGrounds1,
    caption: "Best View Property",
    orientation: "landscape",
    alt: "Best View Cabins Property",
    thumb: bestviewGrounds1Thumb,
  },
  {
    id: 2,
    src: bestviewGrounds3,
    caption: "Best View Property",
    orientation: "landscape",
    alt: "Best View Cabins Property",
    thumb: bestviewGrounds3Thumb,
  },
  {
    id: 3,
    src: bestviewGrounds2,
    caption: "Best View Property",
    orientation: "landscape",
    alt: "Best View Cabins Property",
    thumb: bestviewGrounds2Thumb,
  },
  {
    id: 4,
    src: view,
    caption: "View of the Annapolis Basin",
    orientation: "landscape",
    alt: "Annapolis Basin",
    thumb: viewThumb,
  },
  // {
  //   id: 5,
  //   src: playground,
  //   caption: "Children's Playground",
  //   orientation: "landscape",
  //   alt: "Playground",
  //   thumb: playgroundThumb,
  // },
  {
    id: 6,
    src: pool,
    caption: "Swimming Pool",
    orientation: "landscape",
    alt: "Swimming pool",
    thumb: poolThumb,
  },
  // {
  //   id: 7,
  //   src: firepit,
  //   caption: "Handmade firepits",
  //   orientation: "landscape",
  //   alt: "Handmade firepits",
  //   thumb: firepitThumb,
  // },
]

const AccommodationsGallery = () => (
  <Container>
    <Gallery galleryItems={galleryItems} />
  </Container>
)

export default AccommodationsGallery
