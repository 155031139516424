import React, { Fragment } from "react"
import styled from "styled-components"

import AccommodationsGallery from "../AccommodationsGallery/AccommodationsGallery"
import AccommodationCard from "../AccommodationCard/AccommodationCard"
import {
  Section,
  SectionHeading,
  Container,
  MedContainer,
  H4,
  P,
} from "../BaseElements/BaseElements"
import { media } from "../../variables"
import LazyLoadContainer from "../LazyLoadContainer/LazyLoadContainer"

import bgWaveDark from "./images/bg-wave-dark.jpg"

import largeBathroom from "./images/large-cabin-bathroom.jpg"
import largeBedroom from "./images/large-cabin-bedroom.jpg"
import largeKitchen from "./images/large-cabin-kitchen.jpg"
import largeLiving from "./images/large-cabin-living.jpg"
import largeOutside1 from "./images/large-cabin-outside1.jpg"
import largeOutside2 from "./images/large-cabin-outside2.jpg"
import largeBedroom2 from "./images/large-cabin-bedroom2.jpg"
import largeDining from "./images/large-cabin-dining.jpg"

import regularOutside from "./images/regular-cabin-outside.jpg"
import regularBathroom from "./images/regular-cabin-bathroom.jpg"
import regularBedroom from "./images/regular-cabin-bedroom.jpg"
import regularKitchen from "./images/regular-cabin-kitchen.jpg"
import regularKitchen2 from "./images/regular-cabin-kitchen2.jpg"
import regularLiving from "./images/regular-cabin-living.jpg"

import motelBathroom from "./images/motel-bathroom2.jpg"
import motelBedroom from "./images/motel-bedroom.jpg"
import motelKitchen from "./images/motel-kitchen.jpg"
import motelOutside from "./images/motel-outside2.jpg"

const largeCabin = [
  {
    id: 1,
    src: largeOutside1,
    orientation: "landscape",
    alt: "Exterior",
  },
  {
    id: 2,
    src: largeOutside2,
    orientation: "landscape",
    alt: "Exterior",
  },
  {
    id: 3,
    src: largeBedroom,
    orientation: "landscape",
    alt: "Bedroom",
  },
  {
    id: 4,
    src: largeBedroom2,
    orientation: "landscape",
    alt: "Bedroom",
  },
  {
    id: 5,
    src: largeDining,
    orientation: "landscape",
    alt: "Dining",
  },
  {
    id: 6,
    src: largeKitchen,
    orientation: "landscape",
    alt: "Kitchen",
  },
  {
    id: 7,
    src: largeLiving,
    orientation: "landscape",
    alt: "Living Room",
  },
  {
    id: 8,
    src: largeBathroom,
    orientation: "landscape",
    alt: "Bathroom",
  },
]

const regularCabin = [
  {
    id: 1,
    src: regularOutside,
    orientation: "landscape",
    alt: "Exterior",
  },
  {
    id: 2,
    src: regularBedroom,
    orientation: "landscape",
    alt: "Bedroom",
  },
  {
    id: 3,
    src: regularLiving,
    orientation: "landscape",
    alt: "Living Room",
  },
  {
    id: 4,
    src: regularKitchen2,
    orientation: "landscape",
    alt: "Kitchen",
  },
  {
    id: 5,
    src: regularKitchen,
    orientation: "landscape",
    alt: "Kitchen",
  },
  {
    id: 6,
    src: regularBathroom,
    orientation: "landscape",
    alt: "Bathroom",
  },
]

const motelSuite = [
  {
    id: 1,
    src: motelOutside,
    orientation: "landscape",
    alt: "Exterior",
  },
  {
    id: 2,
    src: motelBedroom,
    orientation: "landscape",
    alt: "Bedroom",
  },
  {
    id: 4,
    src: motelKitchen,
    orientation: "landscape",
    alt: "Kitchen",
  },
  {
    id: 6,
    src: motelBathroom,
    orientation: "portrait",
    alt: "Bathroom",
  },
]

const Accommodations = () => (
  <Section>
    <MedContainer>
      <SectionHeading>Our Accommodations</SectionHeading>
      <P>
        Best View Cabins is a 15-acre family and pet friendly getaway with a
        great view of the Annapolis Basin. Our Cabins are located in
        Clementsport in the Fundy Shore and Annapolis Valley region.
      </P>
      <P>
        Our accommodations include <strong>8 cabins and 2 motel suites</strong>{" "}
        with fully equipped kitchen and barbeque, handmade firepit, deck
        and lawn chairs, and 4-piece bath. Cots and a crib are available. Free
        Wi-Fi and can be accessed at the office. Firewood is available for purchase ($5 per bundle). We also offer an assortment of lawn
        and board games as well as an{" "}
        <strong>outdoor swimming&nbsp;pool</strong> during the summer.
      </P>
    </MedContainer>
    <LazyLoadContainer>
      <AccommodationsGallery />
    </LazyLoadContainer>
    <AccommodationsContainer>
      <Container>
        <AccommodationsRow>
          <div>
            <AccommodationCard
              title="Large Cabin"
              images={largeCabin}
              occupancy="1 to 6 occupancy"
              beds={
                <Fragment>
                  Two bedrooms
                  <ul>
                    <li>One double bed</li>
                    <li>Two single beds</li>
                    <li>One sofa bed</li>
                  </ul>
                </Fragment>
              }
              kitchen="Full Kitchen and Barbeque"
              price="$130"
            />
          </div>
          <div>
            <AccommodationCard
              title="Regular Cabin"
              images={regularCabin}
              occupancy="1 to 4 occupancy"
              beds={
                <Fragment>
                  One bedroom
                  <ul>
                    <li>One double bed</li>
                    <li>One sofa bed</li>
                  </ul>
                </Fragment>
              }
              kitchen="Full Kitchen and Barbeque"
              price="$85"
            />
          </div>
          <div>
            <AccommodationCard
              title="Motel Suites"
              images={motelSuite}
              occupancy="1 to 4 occupancy"
              beds={
                <Fragment>
                  One bedroom
                  <ul>
                    <li>Two double beds</li>
                  </ul>
                </Fragment>
              }
              kitchen="Full Kitchen and Barbeque"
              price="$90"
            />
          </div>
        </AccommodationsRow>

        <RateInfo>
          <H4>Rate Information</H4>
          <ul>
            <li>
              <strong>Weekly Rates</strong> - Stays that are 7 days or longer
              are given a 10% discount and includes 1 cleaning per week.
            </li>
            <li>
              <strong>Monthly Rates</strong> - Stays that are 28 days or longer
              are given a 25% discount and includes 1 cleaning per week.
            </li>
          </ul>
          {/* 
          <H4>Seasonal Information</H4>
          <P>
            Season - June, July, August.
            <br />
            Off Season - May, Mid-September, October.
          </P>
          */}

          <H4>Cancellation Policy</H4>
          <P noMargin={true}>
            Cancellations that are made more than 5 days prior to the check-in
            date will be fully refunded. If the guest cancels less than 5 days
            in advance, the first night is non-refundable but 50% of the
            accommodation fees for remaining nights will be refunded. If the
            guest arrives and decides to leave early, 50% of the accommodation
            fees for the nights not spent 24 hours after the cancellation occurs
            are refunded.
          </P>
        </RateInfo>
      </Container>
    </AccommodationsContainer>
  </Section>
)

const AccommodationsContainer = styled.div`
  padding: 20px 0 0;
  margin: 40px 0 0;
  background: url(${bgWaveDark}) repeat;
  position: relative;
  border-bottom: 1px solid #f2f2f2;

  ${media.sm`
    padding: 40px 0;
  `}

  &:after {
    position: absolute;
    content: "";
    top: 0;
    height: 5px;
    width: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
    z-index: 1000;
    pointer-events: none;
  }
`

const AccommodationsRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${media.sm`
    flex-direction: row;
  `}

  > div {
    flex: 0 0 100%;

    ${media.sm`
      flex: 0 0 32.5%;
    `};

    ${media.md`
      flex: 0 0 32%;
    `};
  }
`

const RateInfo = styled.div`
  background: rgba(255, 255, 255, 0.7);
  margin: 0 0 20px;
  padding: 20px;
  font-size: 14px;

  ${media.sm`
    margin: 20px 0 0 0;
  `}

  > ul {
    margin: 0 0 15px 0;
    padding: 0;
    list-style-position: inside;
  }
`

export default Accommodations
