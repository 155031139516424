import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { vars } from "../../variables"

const Button = ({ href, ...restProps }) =>
  href ? (
    <StyledButton as={Link} to={href} {...restProps} />
  ) : (
    <StyledButton {...restProps} />
  )

const StyledButton = styled.button`
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  padding: 13px 35px 13px 35px;
  border-radius: 4px;
  margin: 10px;
  border: none;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  background: ${({ alt, disabled }) => {
    const bgColor = alt === "true" ? vars.brandcolor2 : vars.brandcolor1
    const disabledColor = alt === "true" ? "#1a885c" : "#3276b1"
    return disabled ? disabledColor : bgColor
  }};
  transition: background 0.2s linear;

  &:hover {
    background: ${({ alt }) => (alt === "true" ? "#1a885c" : "#3276b1")};
  }
`

export default Button
