import React from "react"
import styled from "styled-components"

import {
  Section,
  Container,
  SectionHeading,
} from "../BaseElements/BaseElements"
import AttractionsGallery from "../AttractionsGallery/AttractionsGallery"
import LazyLoadContainer from "../LazyLoadContainer/LazyLoadContainer"
import { media } from "../../variables"

const Attractions = () => (
  <Section>
    <Container>
      <SectionHeading>Nearby Attractions</SectionHeading>
      <AttractionListContainer>
        <div>
          <AttractionList>
            <li>
              <a
                href="https://www.novascotia.com/see-do/outdoor-activities#Region=FundyShore&Community=0&Filters=WhaleWatching&View=Grid&Page=1&IncludeArea=1"
                target="_blank"
                rel="noreferrer noopener"
              >
                Whale Watching
              </a>
            </li>
            <li>
              <a
                href="http://www.historicgardens.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Annapolis Royal Historic Gardens
              </a>
            </li>
            <li>World Famous Digby Scallop Fleet</li>
            <li>
              <a
                href="https://www.novascotia.com/eat-drink/vineyards"
                target="_blank"
                rel="noreferrer noopener"
              >
                Winery Tours
              </a>
            </li>
            <li>
              <a
                href="https://upperclements.com/en/home/upper-clements"
                target="_blank"
                rel="noreferrer noopener"
              >
                Upper Clements Adventure Theme Park
              </a>
            </li>
            <li>
              <a
                href="https://www.nspower.ca/en/home/about-us/how-we-make-electricity/renewable-electricity/annapolis-tidal-station.aspx"
                target="_blank"
                rel="noreferrer noopener"
              >
                North America's First Tidal Power Generating Station
              </a>
            </li>
            <li>
              <a
                href="https://www.bayoffundy.com/activities/bird-watching/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Bird watching and other wildlife
              </a>
            </li>
          </AttractionList>
        </div>
        <div>
          <AttractionList>
            <li>
              <a
                href="https://www.fundyrestaurant.com/rentals"
                target="_blank"
                rel="noreferrer noopener"
              >
                Kayaking
              </a>{" "}
              &{" "}
              <a
                href="https://www.tidalboreadventures.ca/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Fundy Tidal Bore Adventure Rafting
              </a>
            </li>
            <li>
              <a
                href="https://www.novascotia.com/see-do/outdoor-activities/kejimkujik-national-park-seaside/1919"
                target="_blank"
                rel="noreferrer noopener"
              >
                Kejimikujik National Park
              </a>
            </li>
            <li>
              <a
                href="https://www.novascotia.com/see-do/trails/delaps-cove-wilderness-trail/6091"
                target="_blank"
                rel="noreferrer noopener"
              >
                Delaps Cove Wilderness Trails
              </a>
            </li>
            <li>Art Galleries and Artisan Studios</li>
            <li>
              <a
                href="http://www.cornwallismuseum.ca/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Cornwallis Military Museum
              </a>
            </li>
            <li>
              <a
                href="https://fortviewgolf.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Fort View 18 Hole Golf Course
              </a>
            </li>
            <li>
              <a
                href="https://www.novascotia.com/see-do/attractions/fort-anne-national-historic-site/1334"
                target="_blank"
                rel="noreferrer noopener"
              >
                Fort Anne
              </a>{" "}
              &{" "}
              <a
                href="https://www.novascotia.com/see-do/attractions/port-royal-national-historic-site/1462"
                target="_blank"
                rel="noreferrer noopener"
              >
                Port Royal
              </a>{" "}
              National Historic Sites
            </li>
            <li>Picturesque Fishing Village</li>
          </AttractionList>
        </div>
      </AttractionListContainer>
      <LazyLoadContainer>
        <AttractionsGallery />
      </LazyLoadContainer>
    </Container>
  </Section>
)

const AttractionListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  ${media.sm`
    flex-direction: row;
  `}

  > div {
    flex: 0 0 100%;

    ${media.sm`
      flex: 0 0 40%;
    `}
  }
`

const AttractionList = styled.ul`
  margin: 0;

  > li {
    font-size: 20px;
    margin-bottom: 10px;

    ${media.sm`
      margin-bottom: 0;
      font-size: 18px;
    `}
  }
`

export default Attractions
