import React, { useState } from "react"
import Lightbox from "react-images"
import styled from "styled-components"

// import { media, vars } from "../../variables"
import { media } from "../../variables"
import { H3 } from "../BaseElements/BaseElements"
import Loader from "../Loader/Loader"
import Button from "../Button/Button"

import iconBed from "./images/icon-bed.jpg"
import iconGuest from "./images/icon-guest.jpg"
import iconKitchen from "./images/icon-kitchen.jpg"
import iconFire from "./images/icon-fire.jpg"

const AccommodationsCard = ({
  title,
  occupancy,
  beds,
  kitchen,
  price,
  offSeasonPrice,
  images,
  preventScroll,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)

  const openModal = (index, event) => {
    event.preventDefault()
    setCurrentImage(index)
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setCurrentImage(0)
    setModalIsOpen(false)
  }

  const gotoPrevious = () => {
    setCurrentImage(currentImage => currentImage - 1)
  }

  const gotoNext = () => {
    setCurrentImage(currentImage => currentImage + 1)
  }

  const gotoImage = index => {
    setCurrentImage(index)
  }

  const handleClickImage = () => {
    if (currentImage === images.length - 1) return
    gotoNext()
  }

  return (
    <StyledAccommodationsCard>
      <H3>{title}</H3>
      <GalleryLink onClick={e => openModal(0, e)}>View Photos</GalleryLink>
      <Ammenities>
        <Occupancy>{occupancy}</Occupancy>
        <Beds>{beds}</Beds>
        <Kitchen>{kitchen}</Kitchen>
        <Firepit>Firepit</Firepit>
      </Ammenities>
      {/* <Prices>
        <Price long={price.length >= 4}>
          <span>{price}</span>+ Tax <br /> Per Night
        </Price>
        { offSeasonPrice && 
          <Price long={offSeasonPrice.length >= 4}>
            <span>{offSeasonPrice}</span>+ Tax
            <br />
            Per Night (Off Season)
          </Price>
        }
      </Prices> */}
      <Lightbox
        currentImage={currentImage}
        images={images}
        isOpen={modalIsOpen}
        onClickImage={handleClickImage}
        onClickNext={gotoNext}
        onClickPrev={gotoPrevious}
        onClickThumbnail={gotoImage}
        onClose={closeModal}
        preventScroll={preventScroll}
        backdropClosesModal={true}
        spinner={Loader}
      />
    </StyledAccommodationsCard>
  )
}

const StyledAccommodationsCard = styled.div`
  text-align: center;
  background: white;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
  padding: 20px;
  margin: 0 0 20px;

  > h3 {
    text-align: center;
    margin: 0 20px 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e6e6e6;

    ${media.sm`
      margin: 0 20px 20px;
    `}

    ${media.md`
      margin: 0 20px 20px;
    `}
  }
`

const GalleryLink = styled(Button)`
  display: block;
  margin: 0 auto;
`

// const Prices = styled.div`
//   font-size: 14px;
//   display: inline-block;
//   text-align: left;
//   padding-left: 0;

//   @media (min-width: 425px) {
//     padding-left: 40px;
//   }

//   ${media.sm`
//       padding-left: 0;
//     `}
// `

// const Price = styled.div`
//   position: relative;
//   padding: ${({ long }) => (long ? `6px 0 0 80px` : `6px 0 0 63px`)};
//   margin-bottom: 10px;
//   line-height: 15px;

//   > span {
//     font-size: 40px;
//     font-family: ${vars.fontSecondary};
//     position: absolute;
//     left: 0;
//     top: 0;
//     line-height: 43px;
//   }
// `

const Ammenities = styled.ul`
  list-style: none;
  margin: 0 0 20px 0;
  padding: 20px 0 0 0;
  display: inline-block;
  text-align: left;
  font-size: 16px;
  background: #ffffff;

  ${media.sm`
    min-height: 230px;
  `}

  li ul {
    margin: 0 0 0 20px;
    padding: 0;
    font-size: 14px;
    list-style-type: disc;
  }
`

const Icon = styled.li`
  position: relative;
  padding-left: 40px;
  margin-bottom: 15px;

  &:before {
    position: absolute;
    left: 0;
    content: "";
    width: 28px;
    height: 28px;
  }
`

const Occupancy = styled(Icon)`
  &:before {
    background: url(${iconBed}) center center no-repeat;
  }
`

const Beds = styled(Icon)`
  &:before {
    background: url(${iconGuest}) center center no-repeat;
  }
`

const Kitchen = styled(Icon)`
  &:before {
    background: url(${iconKitchen}) center center no-repeat;
  }
`

const Firepit = styled(Icon)`
  &:before {
    background: url(${iconFire}) center center no-repeat;
  }
`

export default AccommodationsCard
