import React from "react"
import styled from "styled-components"

const GalleryImage = ({ src, alt, onClick, thumb }) => (
  <GalleryImageContainer onClick={onClick}>
    <Image src={thumb ? thumb : src} alt={alt} />
  </GalleryImageContainer>
)

const GalleryImageContainer = styled.div`
  cursor: pointer;
`

const Image = styled.img`
  width: 100%;
  cursor: pointer;
`

export default GalleryImage
